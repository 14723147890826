export const okrs = [
    {
      id: 1,
      iconName: 'Mountain',
      title: 'Physical Mastery',
      theme: 'Embodying vitality and strength in every movement',
      objectives: {
        mainGoals: [
          {
            title: 'Movement Goals',
            items: [
              'Achieve perfect form in all fundamental movements',
              'Master advanced calisthenics flows',
              'Regular practice of mobility and flexibility work'
            ]
          },
          {
            title: 'Performance Goals',
            items: [
              'Maintain optimal body composition year-round',
              'Peak athletic performance in chosen disciplines',
              'Injury-free training through perfect form'
            ]
          }
        ],
        sleepMastery: '8 hours of deep, restorative sleep consistently'
      },
      quote: "The body achieves what the mind believes.",
      reflection: "How can I make each movement a meditation?"
    },
    {
      id: 2,
      iconName: 'Star',
      title: 'Inner Cultivation',
      theme: 'Deepening presence and awareness',
      objectives: {
        meditationProgress: {
          daily: '2 hours of focused meditation',
          advanced: 'Regular access to deep meditative states'
        },
        mastery: [
          'Complete mastery over emotional states',
          'Unwavering presence in daily life',
          'Deep understanding of consciousness'
        ],
        impact: 'Guiding others to inner peace and clarity'
      },
      quote: "Stillness is where transformation begins.",
      reflection: "What new depths of consciousness await?"
    },
    {
      id: 3,
      iconName: 'Heart',
      title: 'Life Design',
      theme: 'Creating an environment that nurtures growth',
      objectives: {
        home: {
          location: 'Peaceful location near nature',
          features: [
            'Dedicated meditation space',
            'Home gym with all essential equipment',
            'Minimalist, inspiring workspace',
            'Abundant natural light and fresh air'
          ]
        },
        sleepSpace: {
          title: 'Sleep Sanctuary',
          elements: [
            'Perfect temperature control',
            'Blackout capabilities',
            'Optimal air quality',
            'EMF-free environment'
          ]
        },
        lifestyle: 'Living in harmony with natural rhythms'
      },
      quote: "Design the space that designs you.",
      reflection: "How can my environment better serve my growth?"
    },
    {
      id: 4,
      iconName: 'Zap',
      title: 'Health Innovation',
      theme: 'Pioneering the future of human health',
      objectives: {
        impact: {
          direct: 'Help 1 million people optimize their sleep',
          breakthroughs: 'Pioneer new sleep optimization technologies'
        },
        systems: [
          'Advanced sleep tracking and optimization platform',
          'AI-powered health recommendations',
          'Innovative wellness products and services',
          'Research partnerships with leading institutions'
        ]
      },
      quote: "Innovation is the bridge between dreams and reality.",
      reflection: "What breakthrough could transform human health?"
    },
    {
      id: 5,
      iconName: 'Diamond',
      title: 'Wealth Foundation',
      theme: 'Building sustainable abundance',
      objectives: {
        wealth: {
          passive: 'Generate €50k/month in passive income',
          investments: [
            'Diversified investment portfolio',
            'Strategic real estate holdings',
            'High-growth technology investments',
            'Sustainable passive income streams'
          ]
        },
        businesses: 'Scale health tech ventures to industry leadership',
        purpose: 'Create value through solving meaningful problems'
      },
      quote: "True wealth is measured in positive impact.",
      reflection: "How can wealth creation serve the greater good?"
    },
    {
      id: 6,
      iconName: 'Users',
      title: 'Deep Connection',
      theme: 'Nurturing meaningful relationships',
      objectives: {
        coreRelationships: {
          partner: 'Deep, loving partnership built on growth and support',
          friends: 'Circle of ambitious, growth-minded friends',
          family: 'Strong, nurturing family bonds'
        },
        community: [
          'Lead and inspire a global wellness community',
          'Regular masterminds with thought leaders',
          'Mentor emerging entrepreneurs',
          'Foster meaningful connections worldwide'
        ]
      },
      quote: "We rise by lifting others.",
      reflection: "How can I deepen my impact on others?"
    },
    {
      id: 7,
      iconName: 'RefreshCw',
      title: 'Daily Essence',
      theme: 'Living each day with intention and purpose',
      objectives: {
        states: {
          primary: 'Maintain peak state of flow and presence',
          impact: 'Inspire others through embodied leadership'
        },
        practices: [
          'Morning ritual of movement and meditation',
          'Regular nature immersion and grounding',
          'Continuous learning and growth',
          'Daily gratitude and reflection'
        ],
        essence: 'Being a living example of conscious evolution'
      },
      quote: "Every moment is a chance to begin again.",
      reflection: "How can I make each day a masterpiece?"
    }
  ];